/* eslint-disable @typescript-eslint/no-unused-vars */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Constant } from '../app/shared/constants/constant';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import oktaConfig from './shared/config';
import { AuthGuard } from 'projects/presto-security/src/public_api';

const oktaAuth = new OktaAuth(oktaConfig.oidc);

const routes: Routes = [

  {
    path: '',
    loadChildren: () =>
      import('../app/pages/landing-page/landing.module').then((m) => m.LandingModule),
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'mro',
    loadChildren: () =>
      import('./pages/mro/mro.module').then((m) => m.MroModule),
    canActivate: [OktaAuthGuard, AuthGuard],
    data: { expectedPermission: Constant.PERMISSION_ACCESS_MWS }
  },
  {
    path: 'project',
    loadComponent: () =>
      import('./shared/components/workspace/workspace.component').then((m) => m.WorkspaceComponent),
    canActivate: [OktaAuthGuard, AuthGuard],
    data: { expectedPermission: Constant.PERMISSION_ACCESS_PWS }
  },
  {
    path: 'workspace',
    loadChildren: () =>
      import('../app/pages/workspace-treeview/workspace-treeview.module').then(
        (m) => m.WorkspaceTreeViewSummaryModule
      ),
    // canActivate: [OktaAuthGuard, AuthGuard],
    // data: { expectedPermission: Constant.PERM_READ }
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'item-workspace',
    loadComponent: () => import('../app/pages/tag-workspace/layout/tag-workspace-layout.component').then(m => m.TagWorkspaceLayoutComponent), 
    // canActivate: [OktaAuthGuard, AuthGuard],
    // data: { expectedPermission: Constant.PERM_READ }
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'documents-drawings',
    loadChildren: () =>
      import('../app/pages/documents-drawings/documents-drawings.module').then(
        (m) => m.DocumentsDrawingsModule
      ),
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'pricing-discounts',
    loadComponent: () => import('./pages/pricing-discount/pricing-discount.component').then(mod => mod.PricingDiscountComponent),
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('../app/pages/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
    // canActivate: [OktaAuthGuard, AuthGuard],
    // data: { expectedPermission: Constant.PERM_READ }
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'deconstruct/:serialNumber',
    loadChildren: () =>
      import('../app/pages/mro/deconstruction/deconstruction.module').then(
        (m) => m.DeconstructionModule
      ),
    // canActivate: [OktaAuthGuard, AuthGuard],
    // data: { expectedPermission: Constant.PERM_READ }
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'deconstruct',
    loadChildren: () =>
      import('../app/pages/mro/deconstruction/deconstruction.module').then(
        (m) => m.DeconstructionModule
      ),
    // canActivate: [OktaAuthGuard, AuthGuard],
    // data: { expectedPermission: Constant.PERM_READ }
    canActivate: [OktaAuthGuard],
  },
{
    path: 'search',
    loadChildren: () =>
      import('../app/pages/mro/search-results/search-results.module').then(
        (m) => m.SearchResultsModule
      ),
    // canActivate: [OktaAuthGuard, AuthGuard],
    // data: { expectedPermission: Constant.PERM_READ }
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'error',
    loadChildren: () =>
      import('../app/shared/errors/errors.module').then((m) => m.ErrorsModule),
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' },
  {
    path: 'login/callback',
    component: OktaCallbackComponent,
  },
  {
    path: 'oidc/callback',
    component: OktaCallbackComponent,
  },
];

@NgModule({
  imports: [
    [RouterModule.forRoot(routes)],
    [OktaAuthModule.forRoot({ oktaAuth })],
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
