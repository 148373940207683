import { environment } from '../../environments/environment';

export default {
  oidc: {
    clientId: environment.CLIENT_ID,
    issuer: environment.ISSUER,
    redirectUri: environment.oktaRedirectUri,
    scopes: ['openid', 'profile', 'email']
  }
};


export class Config {
  public SHOW_TAG_LIMIT = 10;
  public CONTENT_TYPE = { 'content-type': 'application/json', "version": "1" }

  private WORKSPACE_PATH = '/api/v1/Workspace/';
  public GET_WORKSPACES_API = environment.prestoApiUrl + this.WORKSPACE_PATH + 'GetWorkspaces'; 
  public CREATE_WORKSPACE_API = environment.prestoApiUrl + this.WORKSPACE_PATH + 'CreateWorkspace';
  public GET_KOB_LIST_API = environment.prestoApiUrl + this.WORKSPACE_PATH + 'GetKOB';
  public GET_KOB3_LIST_API = environment.prestoApiUrl + this.WORKSPACE_PATH + 'GetKOB3';
  public GET_WORKSPACE_STATUS_LIST_API = environment.prestoApiUrl + this.WORKSPACE_PATH + 'GetWorkspaceStatus';
  public POST_COPY_WORKSPACE_API = environment.prestoApiUrl + this.WORKSPACE_PATH + 'CopyWorkspaceUsingDapper';
  public DELETE_WORKSPACE_API = environment.prestoApiUrl + this.WORKSPACE_PATH + 'DeleteWorkspaceUsingDapper';
  public GET_REVISION_NOTATIONS_API = environment.prestoApiUrl + this.WORKSPACE_PATH + 'GetRevisionNotations';
  public GET_WORKSPACE_HEADER_API = environment.prestoApiUrl + this.WORKSPACE_PATH + 'GetWorkspaceHeader';
  public SAVE_WORKSPACE_HEADER_API = environment.prestoApiUrl + this.WORKSPACE_PATH + 'SaveWorkspaceHeader';

  private OFFICE_PATH = '/api/v1/Office/';
  public GET_OFFICE_LIST_API = environment.prestoApiUrl + this.OFFICE_PATH;
  public GET_ALL_OFFICE_LIST_API = environment.prestoApiUrl + this.OFFICE_PATH + 'GetAllOffices';
  public GET_CURRENCY_LIST_API = environment.prestoApiUrl + this.OFFICE_PATH + 'GetCurrencyList';

  private PROJECT_WORKSPACE_PATH = '/api/v1/ProjectWorkSpace/';
  public GET_TREEVIEW_SUMMARY_API = environment.prestoApiUrl + this.PROJECT_WORKSPACE_PATH + 'GetWorkspaceTreeViewSummary'

  private PROJECT_RENAME_GROUP = '/api/v1/Group/';
  public PUT_PROJECT_RENAME_GROUP_API = environment.prestoApiUrl + this.PROJECT_RENAME_GROUP + 'RenameGroup';
  public POST_PROJECT_CREATE_NAME_GROUP_API = environment.prestoApiUrl + this.PROJECT_RENAME_GROUP + 'CreateGroup';
  public DELETE_GROUP_API = environment.prestoApiUrl + this.PROJECT_RENAME_GROUP + 'DeleteGroup';

  private PROJECT_ITEM = '/api/v1/Item/';
  public POST_CREATE_ITEM_API = environment.prestoApiUrl + this.PROJECT_ITEM + 'CreateItem';
  public PUT_RENAME_ITEM_API = environment.prestoApiUrl + this.PROJECT_ITEM + 'RenameItem';
  public DELETE_ITEM_API = environment.prestoApiUrl + this.PROJECT_ITEM + 'DeleteItem';
  public PUT_PROJECT_ITEM_QTY_API = environment.prestoApiUrl + this.PROJECT_ITEM + 'UpdateItemQuantity';
  public PUT_PROJECT_ITEM_UPDATE_API = environment.prestoApiUrl + this.PROJECT_ITEM + 'UpdateItemName';
  public PUT_WORKSPACE_ITEM_UPDATE_HASHTAG_API = environment.prestoApiUrl + this.PROJECT_ITEM + 'UpdateHashTag';
  public DUPLICATE_ITEM_API = environment.prestoApiUrl + this.PROJECT_ITEM + 'PasteItem';
  public PUT_UPDATE_ITEM_GROUP = environment.prestoApiUrl + this.PROJECT_ITEM + 'UpdateItemGroup';
  public UPDATE_ITEM_NUMBER = environment.prestoApiUrl + this.PROJECT_ITEM + 'UpdateItemNumber';

  private WORKSPACE_CREATE_REVISIONS = '/api/v1/ItemRevision/';
  public POST_WORKSPACE_CREATE_REVISIONS_API = environment.prestoApiUrl + this.WORKSPACE_CREATE_REVISIONS + 'CreateItemRevisions';
  public PASTE_WORKSPACE_PASTETE_REVISIONS_API = environment.prestoApiUrl + this.WORKSPACE_CREATE_REVISIONS + 'PasteItemRevision';
  public DELETE_WORKSPACE_DELETE_REVISIONS_API = environment.prestoApiUrl + this.WORKSPACE_CREATE_REVISIONS + 'DeleteItemRevision';

  private PROJECT_CONSTRUCTION = '/api/v1/Construction/';
  public PUT_PASTE_CONSTRUCTION_API = environment.prestoApiUrl + this.PROJECT_CONSTRUCTION + 'PasteConstruction';
  public PUT_UPDATE_CONSTRUCTION_API = environment.prestoApiUrl + this.PROJECT_CONSTRUCTION + 'UpdateConstructionDescription';
  public DELETE_CONSTRUCTION_API = environment.prestoApiUrl + this.PROJECT_CONSTRUCTION + 'DeleteConstruction';
  public SET_AS_PRIMARY_CONSTRUCTION_API = environment.prestoApiUrl + this.PROJECT_CONSTRUCTION + 'SetAsPrimary';
  public CREATE_ALTERNATE_CONSTRUCTION_API = environment.prestoApiUrl + this.PROJECT_CONSTRUCTION + 'CreateAlternate';

  private PROJECT_UOMDEFAULT = '/api/v1/UOMDefault/';
  public GET_UOMDEFAULTS_API = environment.prestoApiUrl + this.PROJECT_UOMDEFAULT + 'GetUOMDefaults';
  public POST_UOMDEFAULTS_API = environment.prestoApiUrl + this.PROJECT_UOMDEFAULT + 'SaveUOMDefaults';

  private CONFIG_PATH = "/api/v1/configIt";
  public CONFIGIT_UPDATE_COMPONENT_STRUCTURE_API = environment.prestoApiUrl + this.CONFIG_PATH + '/updateComponentStructure';
  public CONFIGIT_GETSESSION_API = environment.prestoApiUrl + this.CONFIG_PATH + "/getSession";
  public CONFIGIT_REMOVESESSION_API = environment.prestoApiUrl + this.CONFIG_PATH + "/removeSession";
  public CONFIGIT_CONFIGURE_API = environment.prestoApiUrl + this.CONFIG_PATH + '/configure';
  public CONFIGIT_COMPLETE_API = environment.prestoApiUrl + this.CONFIG_PATH + '/complete';
  public CONFIGIT_REPRICE_API = environment.prestoApiUrl + this.CONFIG_PATH + '/reprice';
  public CONFIGIT_SPECIALS_API = environment.prestoApiUrl + this.CONFIG_PATH + '/specials';

  public GET_PROJECTTAG_API = environment.prestoApiUrl + this.PROJECT_WORKSPACE_PATH + 'GetProductTree';

  private PROJECT_BUILDASSEMBLY_PATH = "/api/v1/BuildAssembly/";
  public POST_SAVE_BUILDASSEMBLY_API = environment.prestoApiUrl + this.PROJECT_BUILDASSEMBLY_PATH + 'SaveBuildAssembly';
  public POST_FETCH_BUILDASSEMBLY_API = environment.prestoApiUrl + this.PROJECT_BUILDASSEMBLY_PATH + 'FetchBuildAssembly';

  private PROJECT_SIZING_PATH = "/api/v1/Sizing/";
  public GET_SIZINGMETHODS_API = environment.prestoApiUrl + this.PROJECT_SIZING_PATH + 'GetSizingMethods';
  public GET_SIZINGOPTIONS_API = environment.prestoApiUrl + this.PROJECT_SIZING_PATH + 'GetSizingOptions';
  public GET_SIZINGMETHODSWITHOPTIONS_API = environment.prestoApiUrl + this.PROJECT_SIZING_PATH + 'GetSizingMethodsWithOptions';
  public GET_VALVESIZINGDATA_API = environment.prestoApiUrl + this.PROJECT_SIZING_PATH + 'GetValveSizingData';
  public TRANSFORMED_SIZING_VARIABLES_API = environment.prestoApiUrl + this.PROJECT_SIZING_PATH + 'TransformedSizingVariables';
  public CALCULATE_SIZING_CATALOG_API = environment.prestoApiUrl + this.PROJECT_SIZING_PATH + 'CalculateSizingCatalog';
  public TRANSFORM_CHANGEUOM_API = environment.prestoApiUrl + this.PROJECT_SIZING_PATH + 'TransformChangeUOM';
  public TRANSFORMED_REFRESHPAIREDVARIABLES_API = environment.prestoApiUrl + this.PROJECT_SIZING_PATH + 'TransformRefreshPairedVariables';

  private USER_PATH = "/api/v1/User/";
  public GET_USERINFO_API = environment.prestoApiUrl + this.USER_PATH + "getAuthorizedUserInfo";

  private PRICE_PATH = "/api/v1/Price/";
  public PRICE_WORKSPACE_REPRICE_API = environment.prestoApiUrl + this.PRICE_PATH + 'WorkspaceReprice';
  public PRICE_GETCURRENCY_CONVERSION_FACTOR_API = environment.prestoApiUrl + this.PRICE_PATH + 'GetCurrencyConversionFactor';
  public PRICE_REPRICE_API = environment.prestoApiUrl + this.PRICE_PATH + 'Reprice';
  public PRICE_GET_PRICING_HEADER_API = environment.prestoApiUrl + this.PRICE_PATH + 'GetPricingHeader/';
  public PRICE_SAVE_PRICE_HEADER = environment.prestoApiUrl + this.PRICE_PATH + 'SavePriceHeader';

  private LOCK_PATH = "/api/v1/Lock/";
  public LOCK_WORKSPACE = environment.prestoApiUrl + this.LOCK_PATH + 'LockWorkspace';
  public UNLOCK_WORKSPACE = environment.prestoApiUrl + this.LOCK_PATH + 'UnLockWorkspace'; 
  public UNLOCK_ITEM = environment.prestoApiUrl + this.LOCK_PATH + 'UnLockItem';
  //MRO
  private MRO_PATH = "/api/v1/MRO/";
  public GET_SERIAL_CARD_API = environment.prestoApiUrl + this.MRO_PATH + 'SerialSearch';
  public GET_CONSTRUCTION_DETAILS_API = environment.prestoApiUrl + this.MRO_PATH + 'GetConstructionDetails';
  public GET_RECOMMENDED_SPARES_API = environment.prestoApiUrl + this.MRO_PATH + 'GetRecommendedSparesInfo';
  public GET_EXPORT_ALL_SPARES = environment.prestoApiUrl + this.MRO_PATH + 'SparesExcelExport';
  public GET_BOM_INFO = environment.prestoApiUrl + this.MRO_PATH + 'GetBOMInfo';
  public GET_PRODUCT_ATTRIBUTE_INFO = environment.prestoApiUrl + this.MRO_PATH + 'GetProductsInfo';
  public GET_RECENT_SEARCH_INFO = environment.prestoApiUrl + this.MRO_PATH + 'GetUserActivityLog';
  public GET_BULK_SEARCH_DOWNLOAD_TEMPLATE = environment.prestoApiUrl + this.MRO_PATH +'GetBulkSearchUploadTemplate';
  public POST_BULK_SEARCH_UPLOAD = environment.prestoApiUrl + this.MRO_PATH + 'GetExcelSerialSearch';
  public GET_EXPORT_ATTRIBUTES = environment.prestoApiUrl + this.MRO_PATH + 'ProductsExcelExport';
  public GET_MFG_LOCATIONS = environment.prestoApiUrl + this.MRO_PATH + 'GetOrganizationInfo';
  public GET_NOTE_TYPES = environment.prestoApiUrl + this.MRO_PATH + 'GetNoteTypeInfo';
  public GET_DOCUMENTLIST = environment.prestoApiUrl + this.MRO_PATH + 'GetDocumentList';
  public GET_DOCUMENT_FILE = environment.prestoApiUrl + this.MRO_PATH + 'GetDocumentFile';
  public GET_FLEX_SERIAL_CARD = environment.prestoApiUrl + this.MRO_PATH + 'GetFlexSerialCard';
  public GET_FLEX_SERIAL_CARD_REPORT = environment.prestoApiUrl + this.MRO_PATH + 'GetFlexSerialCardReports';
  public GET_SPIREXCELREPORT = environment.prestoApiUrl + this.MRO_PATH + 'SPIRExcelReport';
  public GET_PRODUCT_COMPARE = environment.prestoApiUrl + this.MRO_PATH + 'ProductsCompare';
  public GET_PN_COMPARE_EXCEL_EXPORT = environment.prestoApiUrl + this.MRO_PATH + 'PNCompareExcelExport';
  public GET_FIELD_CHANGE = environment.prestoApiUrl + this.MRO_PATH + 'GetFieldsChanges';
  public GET_PARTS_DETAILS = environment.prestoApiUrl + this.MRO_PATH + 'GetPartsDetails';
  public GET_BULK_PARTS_DATA = environment.prestoApiUrl + this.MRO_PATH + 'GetExcelPartsData';
  public GET_SEARCH_ADDITIONAL_FIELDS = environment.prestoApiUrl + this.MRO_PATH + 'GetSearchAdditionalFields';
  public GET_PACKING_LIST = environment.prestoApiUrl + this.MRO_PATH + 'PackingList';
  public GET_PRODUCTS_EMSTRING_DATA = environment.prestoApiUrl + this.MRO_PATH + 'GetProductAndEMStringData';
  public GET_COMPARED_INFO = environment.prestoApiUrl + this.MRO_PATH + 'GetComparedInfo';
  public GET_SERIAL_COMPARE_EXCEL_EXPORT = environment.prestoApiUrl + this.MRO_PATH + 'CompareInfoExcel';

  private PROJECT_CATALOG_PATH = "/api/v1/ValveCatalog/";
  public GET_VALVECATALOGBASE_API = environment.prestoApiUrl + this.PROJECT_CATALOG_PATH;
  public GET_VALVECATALOGPRODUCTS_API = this.GET_VALVECATALOGBASE_API + 'GetValveCatalog';
  public GET_VALVECATALOGCOEFFICIENTVALUES_API = this.GET_VALVECATALOGBASE_API + 'GetCoefficientValues';
  POST_SAVE_GRAPHIMAGE_API = environment.prestoApiUrl + this.PROJECT_BUILDASSEMBLY_PATH + 'SaveGraphImage';
  public GET_GRAPHIMAGE_API = environment.prestoApiUrl + this.PROJECT_BUILDASSEMBLY_PATH + 'GetGraphImage';

  private PROJECT_ACTUATOR_PATH = "/api/v1/ActuatorSizing/";
  public GET_ACTUATOR_API = environment.prestoApiUrl + this.PROJECT_ACTUATOR_PATH;
  public GET_ACTUATOR_SIZING_DATA = this.GET_ACTUATOR_API + 'GetActuatorSizing';
  public INITIATE_ACTUATOR_SIZING = this.GET_ACTUATOR_API + 'Initialize';

}