import { BrokenLinkType } from "src/app/shared/enums/buildassembly";
import { Assignment, BreakRule, Component as ConComponent } from "src/app/pages/tag-workspace/store/models/build-assembly/buildassembly";

class Component {
    guid: string;
    productLineId: number;
    componentName: string;
    productLineName: string;
    isBroken: boolean;
    productNameWithComponentName: string;
    isBrokenAttributeFound: boolean;

    constructor(_guid: string, _productLineId: number, _componentName: string, _productLineName: string, _isBroken: boolean, _isBrokenAttributeFound: boolean) {
        this.guid = _guid;
        this.productLineId = _productLineId;
        this.componentName = _componentName;
        this.productLineName = _productLineName;
        this.productNameWithComponentName = this.componentName + " - " + this.productLineName;
        this.isBroken = _isBroken;
        this.isBrokenAttributeFound = _isBrokenAttributeFound;
    }
}

export class SpecialInfoUI {
    sepcial: boolean;
    hasBrokenLink: boolean;
    turnOnBuildAssemblyRules: boolean;
    components: Component[];
    brokenComponents: Component[];
    componentsWithBrokenAttributes: Component[];
    componentsWithoutBrokenAttributes: Component[];

    constructor() {
        this.hasBrokenLink = false;
        this.turnOnBuildAssemblyRules = true;
        this.sepcial = false;
        this.components = [];
        this.brokenComponents = [];

        this.componentsWithBrokenAttributes = [];
        this.componentsWithoutBrokenAttributes = [];
    }

    AssignBrokenInfo(primaryVariables: Assignment[], secondaryVariables: Assignment[]) {
        let isBrokenAttributeFound: boolean = false;
        if (primaryVariables.find((asn: Assignment) => asn.isBroken === true)) {
            isBrokenAttributeFound = true;
        }

        if (secondaryVariables.find((asn: Assignment) => asn.isBroken === true)) {
            isBrokenAttributeFound = true;
        }

        this.componentsWithBrokenAttributes = [];
        this.componentsWithoutBrokenAttributes = [];

        this.brokenComponents.forEach((comp: Component) => {
            if (comp.isBrokenAttributeFound && isBrokenAttributeFound) {
                this.componentsWithBrokenAttributes.push(comp);
            } else {
                this.componentsWithoutBrokenAttributes.push(comp);
            }
        })
    }

    AddBrokenComponent(guid: string, productLineId: number, componentName: string, productLineName: string, isBroken: boolean, primaryVariables: Assignment[], secondaryVariables: Assignment[]) {
        let isBrokenAttributeFound: boolean;
        isBrokenAttributeFound = false;

        if (primaryVariables.find((asn: Assignment) => asn.isBroken === true)) {
            isBrokenAttributeFound = true;
        }

        if (secondaryVariables.find((asn: Assignment) => asn.isBroken === true)) {
            isBrokenAttributeFound = true;
        }

        let component: Component = new Component(guid, productLineId, componentName, productLineName, isBroken, isBrokenAttributeFound);

        this.components.push(component);
        if (isBroken) {
            this.brokenComponents.push(component);
        }
    }

    GetBreakRules(component: ConComponent): Array<BreakRule[]> {
        let breakRules: Array<BreakRule[]> = [];
        let breakRuleSet: BreakRule[];

        this.brokenComponents.forEach((comp: Component) => {
            //To ignore the exitsing Broken Links - RR will revisit this once API changes are ready
            if (comp.isBroken === false) {
                breakRuleSet = [];
                breakRuleSet.push(new BreakRule(component.guid!, component.productLineId!.toString(), BrokenLinkType.BrokenByUser));
                breakRuleSet.push(new BreakRule(comp.guid, comp.productLineId.toString(), BrokenLinkType.BrokenByUser));
                breakRules.push(breakRuleSet);
            }
        })

        return breakRules;
    }
}