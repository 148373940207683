import { EventEmitter, Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { ValveSizingRequest } from "../../../models/sizevalve/valve.sizing.request";
import { ISummaryView } from "src/app/pages/workspace-treeview/model/project-tree-view.model";
import { TagWorkSpaceTab } from "src/app/shared/enums/tag-workspace";
import { ConfirmSelectParam, ConstructionSelectParam } from "../../../models/tag-workspace/tagworkspace.service.params";


@Injectable({
  providedIn: 'root',
})
export class TagWorkSpaceService {
  
  private valveSizingRequestSubject = new BehaviorSubject<ValveSizingRequest | null>(null);
  valveSizingRequest$ = this.valveSizingRequestSubject.asObservable();

  private isConfirmSelectionSubject = new BehaviorSubject<ConfirmSelectParam>({confirm: null, fromCatalog: false});
  isConfirmSelection$ = this.isConfirmSelectionSubject.asObservable();

  private constructionSelectSubject = new BehaviorSubject<ConstructionSelectParam | null>(null);
  constructionSelect$ = this.constructionSelectSubject.asObservable();

  private constructionRevertSubject = new BehaviorSubject<number | null>(null);
  constructionRevert$ = this.constructionRevertSubject.asObservable();

  private isPtValidationCancelledSubject = new BehaviorSubject<boolean | null>(null);
  isPtValidationCancelled$ = this.isPtValidationCancelledSubject.asObservable();  

  private tagDetailsSubject = new BehaviorSubject<ISummaryView | null>(null);
  tagDetails$ = this.tagDetailsSubject.asObservable();

  private tagWorkSpaceTabSubject = new BehaviorSubject<TagWorkSpaceTab | null>(null);
  tagWorkSpaceTab$ = this.tagWorkSpaceTabSubject.asObservable();

  private tagWorkSpaceBreadCrumbSubject = new BehaviorSubject<string | null>(null);
  tagWorkSpaceBreadCrumb$ = this.tagWorkSpaceBreadCrumbSubject.asObservable();

  private isSaveConfirmEnabledSubject = new BehaviorSubject<boolean>(false);

  private calculateButtonSource = new Subject<void | null>();
  calculateButtonClicked = this.calculateButtonSource.asObservable();

  public onManualSizeButtonClick$: EventEmitter<any>;

  private noActuatorSubject = new BehaviorSubject<boolean | null>(false);
  noActuator$ = this.noActuatorSubject.asObservable();

  private constructionLoadCompleteSubject = new BehaviorSubject<boolean | null>(false);
  constructionLoadComplete$ = this.constructionLoadCompleteSubject.asObservable();

  constructor() {
    this.onManualSizeButtonClick$ = new EventEmitter<any>();
  }

   updateValveSizingRequest(valveSizingRequest: ValveSizingRequest){
    this.valveSizingRequestSubject.next(valveSizingRequest);
  }

  set isConfirmSelection(value: ConfirmSelectParam) {
    this.isConfirmSelectionSubject.next(value);
  }

  set isPtValidationCancelled(value: boolean | null) {
    this.isPtValidationCancelledSubject.next(value);
  }

  set constructionSelect(value: ConstructionSelectParam | null) {
    this.constructionSelectSubject.next(value);
  }

  set constructionRevert(value: number | null) {
    this.constructionRevertSubject.next(value);
  }

  set tagDetails(data: ISummaryView | null) {
    this.tagDetailsSubject.next(data);
  }

  get tagDetails(): ISummaryView | null {
    return this.tagDetailsSubject.getValue();
  }

  set tagWorkSpaceTab(value: TagWorkSpaceTab | null) {
    this.tagWorkSpaceTabSubject.next(value);
  }

  get tagWorkSpaceTab() {
    return this.tagWorkSpaceTabSubject.getValue();
  }

  set tagWorkSpaceBreadCrumb(value: string | null) {
    this.tagWorkSpaceBreadCrumbSubject.next(value);
  }

  get tagWorkSpaceBreadCrumb() {
    return this.tagWorkSpaceBreadCrumbSubject.getValue();
  }

  set isSaveConfirmEnabled(value: boolean) {
    this.isSaveConfirmEnabledSubject.next(value);
  }

  get isSaveConfirmEnabled(): boolean {
    return this.isSaveConfirmEnabledSubject.value;
  }

  calculateButtonClick() {
    this.calculateButtonSource.next();
  }

  get noActuator(): boolean | null {
    return this.noActuatorSubject.value;
  }

  set noActuator(value: boolean | null) {
    this.noActuatorSubject.next(value);
  }

  set constructionLoadComplete(value: boolean | null) {
    this.constructionLoadCompleteSubject.next(value);
  }


  clearTagWorkSpaceState() {
    this.isConfirmSelectionSubject.next({ confirm: null, fromCatalog: false });
    this.valveSizingRequestSubject.next(null);
    this.constructionSelectSubject.next(null);
    this.constructionRevertSubject.next(null);
    this.tagWorkSpaceTabSubject.next(null);
    this.tagWorkSpaceBreadCrumbSubject.next(null);
    this.isSaveConfirmEnabledSubject.next(false);
    this.constructionLoadCompleteSubject.next(null);
  }
}
