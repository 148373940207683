import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable, Version } from "@angular/core";
import { OKTA_AUTH } from "@okta/okta-angular";
import { OktaAuth } from "@okta/okta-auth-js";
import { Observable, catchError, finalize, noop, tap } from "rxjs";
import { CommonUtilityLibraryService } from '../library/common-utility-library.service';
import { Router } from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private requests: number = 0;
  constructor(
    public culibrary: CommonUtilityLibraryService,
    @Inject(OKTA_AUTH)
    public oktaAuth: OktaAuth,
    private router: Router) { }

  intercept<T>(
    request: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    
    ///Loader is not required while creating new Session and Reprice
    if (!request.url.includes("/getSession") && !request.url.includes("/Reprice")) {
      this.requests++;
    }

    this.culibrary.show();
    let authReq: HttpRequest<T> = request;
    const token = this.oktaAuth.getAccessToken();
    if (token) {
      if (!(request.body instanceof FormData)) {
        authReq = request.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          },
          withCredentials: true
        });
      } else {
        authReq = request.clone({
          headers: request.headers.delete('Content-Type').set('Authorization', 'Bearer ' + token),
          withCredentials: true
        });
      }
    } 
    return next.handle(authReq).
      pipe(
        tap(() => { noop }),
        finalize(() => {
          if (this.requests > 0) this.requests--;
          if (this.requests === 0) {
            this.culibrary.hide();
          }
        }),
        catchError((error: HttpErrorResponse) => {
          console.error("HTTP Request Error:", error?.message, error?.status, error?.statusText, error?.url, error?.error);
          // if (error.status === 400) {
          //   this.router.navigate(['/error/bad-request']);
          //   throw (error);
          // }
          // if (error.status === 401) {
          //   this.router.navigate(['/error/access-denied']);
          //   throw (error);
          // }
          // if (error.status === 404) {
          //   this.router.navigate(['/error/not-found']);
          //   throw (error);
          // }
          // this.router.navigate([`/error/server-error/${error.status}`]);
          throw (error);
        })
      );
  }

}