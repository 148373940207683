import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
CommonModule;
@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  @Input() theme: 'primary'| 'default' | 'outline' | 'outline-primary' | 'outline-delete' | 'outline-green' |'outline-transparent' | 'outline-white' = 'default';
  @Input() hasIcon: boolean = false;
  @Input() iconSrc: string = '';
  @Input() appendClassName: string = '';
  @Input() label: string = '';
  @Input() type: 'button' | 'submit' | 'reset' | 'split' = 'button';
  @Input() hasIconTemplate: boolean = false;
  @Input() iconPlacement: 'left' | 'right' = 'left';
  @Input() disabled = false;
  @Input() appendBtnDisabled: boolean = false;
  @Input() ico = false;
  @Input() iconClasses: string = '';

  computeButtonClasses(): string {
    let classes = '';

    
    // Default button classes
    if (this.type !== 'split') {
      classes +=
        'shrink-0 whitespace-nowrap px-2 py-2 bg-white rounded-lg justify-center items-center gap-2 inline-flex button-custom-border border-gray-100 text-gray-700 text-sm font-medium font-inter'; // Add your default classes here
    }

    // Theme-specific classes
    if (this.theme === 'outline') {
      classes += ' custom-text-black border-white !bg-transparent text-white'; // Add outline theme classes here
    }

    if (this.theme === 'outline-primary') {
      classes +=
        '!bg-transparent outline-primary-button button-custom-border-green-primary custom-border-1 custom-text-green-primary hover:!bg-green-primary hover:!text-white'; // Add outline-primary theme classes here
    }
    if (this.theme === 'primary') {
      classes +=
        ' custom-text-black !bg-green-primary disabled:cursor-not-allowed disabled:opacity-70 text-white button-custom-border-green-primary hover:!bg-green-light duration-300 !transition-all hover:!transition-all hover:!border-green-light transiton-all'; // Add primary theme classes here
    }
    if (this.theme === 'outline-green') {
      classes +=
        ' custom-text-black font-inter !text-sm px-4 !py-[8px] font-semibold bg-white !text-[#00573D] border !border-[#00573D] rounded-lg hover:!bg-[#00573D] hover:!border hover:!border-[#00573D] hover:!text-white hover:transition-all hover:duration-700 hover:ease-in-out'; // For Secondary theme classes here
    }
    if (this.theme === 'outline-white') {
      classes +=
        ' custom-text-black sfont-inter !text-sm px-4 !py-[8px] font-medium !bg-[#00573D] text-white  border !border-[#00573D] rounded-lg hover:!bg-green-light hover:!border hover:!border-[#00573D] hover:!text-white hover:transition-all hover:duration-700 hover:ease-in-out'; // For Primary theme classes here
    }
    if (this.theme === 'outline-transparent') {
      classes +=
        'bg-transparent p-2 !border !border-emerald-800 font-noto text-sm rounded-lg cursor-pointer bg-white justify-center items-center gap-2 inline-flex text-black text-sm font-semibold flex gap-2 rounded-r-lg s-buttons !text-[#00573D] border !border-[#00573D] bg-transparent'; // For Primary theme classes here
    }
    
    if(this.appendBtnDisabled) {
      classes += ' !cursor-not-allowed opacity-50';
    }

    if (this.hasIcon) {
      classes += ' with-icon';
    }

    // Appended classes
    if (this.appendClassName) {
      classes += ' ' + this.appendClassName;
    }
    
    return classes;
  }
}
