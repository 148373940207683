import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface ICustomInputOption {
  value: string;
  label: string,
  checked: boolean
}
@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomInputComponent),
      multi: true
    }
  ]
})
export class CustomInputComponent implements ControlValueAccessor {
  searchOptions: ICustomInputOption[] = [
    { value: 'option1', label: 'Option 1', checked: false },
    { value: 'option2', label: 'Option 2', checked: false },
    // ...
  ];
  @Input() type:
  | 'text'
  | 'email'
  | 'number'
  | 'checkbox'
  | 'radio'
  | 'date'
  | 'select'
  | 'textarea' = 'text'; // Default to text type
  @Input() label = '';
  @Input() name = '';
  @Input() value = '';
  @Input() placeholder = '-';
  @Input() hint = '';
  @Input() labelClass = '';
  @Input() inputClass = ''; // Custom class binding for the input element
  @Input() wrapperClass = ''; // Custom class binding for the input element
  @Input() iconClass = ''; // FontAwesome icon class
  @Input() iconPosition: 'left' | 'right' = 'right'; // Default to right position
  @Input() hasIcon = false;
  @Input() icon = '';
  //@Input() mroptions: { value: string; label: string }[] = []; // Options for checkbox and radio inputs
  @Input() set options(data: ICustomInputOption[]) { // Options for checkbox and radio inputs
    this.optionList = data;
  }
  @Input() maxLength = 0;
  @Output() inputChange: EventEmitter<string> = new EventEmitter<string>();
  onInputChange(event: any) {
    this.inputChange.emit(event.target.value);
  }
  get options(): ICustomInputOption[] {
    return this.optionList;
  }
  @Input() set optionsJSON(data: string) {
    if (data) {
      this.optionList = JSON.parse(data);
    }
  }
  get optionsJSON(): string {
    return JSON.stringify(this.optionList);
  }

  isDisabled: boolean;
  optionList: ICustomInputOption[] = [];
  onChange: any = () => {};
  onTouch: any = () => {};

  constructor() {}

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  updateValue(val: any) {
    this.value = val;
    this.onChange(val);
    this.onTouch(val);
  }

}
