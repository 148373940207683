import { ChartDataPoint } from "./chart.row.data";

export class ShortStrokeInfo {
    isShortStroke: boolean;                // Indicates if restricted travel is enabled
    shortStrokeTravel: number | null;             // The restricted travel value (in inches)
    shortStrokeMaxRatedCv: number | null;         // The maximum rated Cv for the restricted travel
    shortStrokeMaxRatedOpen: number | null;       // The percentage open for the restricted travel
    shortStrokeMaxRatedOpenDouble: number | null; // The percentage open for the restricted travel (Without precision)
    shortStrokeTravelInvalid: boolean;     // Indicates if the restricted travel value is invalid
    chartData: ChartDataPoint[] | null;
  }