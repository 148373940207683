import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Config } from '../../config';
import { Construction, Session } from 'src/app/pages/tag-workspace/store/models/build-assembly/buildassembly';
import { ItemIdentifier } from '../../models/project-worksapce/itemidentifier';
import { CommonApiService } from '../common-api.service';

@Injectable({
  providedIn: 'root',
})
export class SessionManageService {
  private config: Config;

  constructor(private apiService: CommonApiService) {
    this.config = new Config();
  }

  public getSession(): Observable<Session> {
    return this.apiService.getJSON(
      this.config.CONFIGIT_GETSESSION_API,
    );
  }

  public removeSession(payLoad: string) {
    return this.apiService.post(
      this.config.CONFIGIT_REMOVESESSION_API,
      JSON.stringify(payLoad)
    );
  }

  saveSession(session: Session) {
    if (session?.newSession) {         
    }
    sessionStorage.setItem("session", JSON.stringify(session));
}

}
