import { Guid } from "guid-typescript";
import { AssociateProduct } from "./associateproduct";

export class Product {
    id: number;
    name: string;
    family: string;
    count: number;
    guid: string;

    associateProduct: AssociateProduct;

    constructor(_productLineId: number, _productLineName: string, _productFamily: string, _guid?: string, _parent?: AssociateProduct) {
        let newGUID;
        newGUID = Guid.create();

        this.id = _productLineId;
        this.name = _productLineName;
        this.family = _productFamily;
        this.count = 1;

        if (_guid) {
            this.guid = _guid
        } else {
            this.guid = newGUID['value'];
        }

        if (_parent?.guid) {
            this.associateProduct = _parent;
        }
    }
}