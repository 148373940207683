
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ItemLike": [
      "Item",
      "RepairKit",
      "TrimCartridge"
    ],
    "RecommendedSpare": [
      "ItemQuantity",
      "RepairKit",
      "TrimCartridge"
    ]
  }
};
      export default result;
    