import { PriceGroupType, PriceType, ListNet, } from "../../enums/buildassembly";

export class Price {
    priceId?: number;
    constructionId?: number;
    componentId?: string;
    guid?: string;
    sequenceNumber?: number;
    groupType: PriceGroupType;
    priceType: PriceType;
    description: string;
    unitListPrice: string;
    unitPrice?: string;
    totalPrice?: string;
    totalPriceFull?: string;
    dOS?: string;
    priceFactor?: string;
    priceFactorFull?: string;
    cNP?: string;
    extDiscountFactor?: string;
    listNet: ListNet;
    hasPPI?: boolean;
    cF: boolean;
    discountPercentage?: string;
    createdBy?: number;
    createdDate?: Date;
    commissionPercent: string;
    productType?: string;
    isDummyRow: boolean;

    priceGroupCode: string;
    componentName: string;
    isComplete: boolean;
    isSpecial: boolean;

    constructor() {
        this.groupType = PriceGroupType.None;
        this.priceType = PriceType.None;
        this.listNet = ListNet.None;
        this.dOS = "";
        this.unitListPrice = "";
        this.description = "";
        this.cF = false;
        this.isDummyRow = false;

        this.priceGroupCode = "";
        this.componentName = "";
        this.isComplete = false;
        this.isSpecial = false;
    }
}