import { Session } from  'src/app/pages/tag-workspace/store/models/build-assembly/buildassembly';

export class ItemIdentifier {    
    userId:string; //Project workspace APIs are using Pascal case
    workspaceId: number;
    itemId: number;
    itemQuantity: number;
    itemName?: string;
    revisionId: number;
    revisionName?: string;
    constructionTypeId?: number;
    constructionType?: string;
    constructionVersionId?: number;
    constructionId: number;
    isPresentWorkspace?: boolean;

    sourceItemId?:number;
    destItemId?: number;    
    groupId?: number;
    effectivePriceDate?: Date;

    session: Session;

    constructor(){
        this.userId = "1";
        this.workspaceId = 0;
        this.itemId = 0;
        this.itemQuantity = 1;
        this.revisionId = 0;
        this.constructionTypeId = 0;
        this.constructionVersionId = 0;
        this.constructionId = 0;
        
        this.sourceItemId = 0;
        this.destItemId = 0;

        this.isPresentWorkspace = false;
        this.itemName = "";
        this.revisionName = "";
        this.constructionType = "";

        this.session = new Session();
    }

    Initialize(session: Session, workspaceId: number, itemId: number, taQuantity: number, revisionId: number, constructionTypeId: number, constructionVersionId: number, construictionId: number, itemName: string, revisionName: string, constructionType: string) {
        this.session = session;
        this.workspaceId = workspaceId;
        this.itemId = itemId;
        this.revisionId = revisionId;
        this.constructionTypeId = constructionTypeId;
        this.constructionVersionId = constructionVersionId;
        this.constructionId = construictionId;
        this.itemQuantity = taQuantity;
        this.itemName = itemName;
        this.revisionName = revisionName;
        this.constructionType = constructionType;
    }
}