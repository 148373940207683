export class Constraint {
   temperature: string;
   pressure: string;
   shutoff: string;
   flowDrop: string;

   constructor() {
      this.temperature = "";
      this.pressure = "";
      this.shutoff = "";
      this.flowDrop = "";
   }
}