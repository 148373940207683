<label [for]="id" class="flex items-center cursor-pointer">
    <div class="mr-3 font-medium" [ngClass]="leftLabelClasses">{{ leftLabel }}</div>
    <div class="relative primary">
        <input type="checkbox" [disabled]="disabled" [id]="id" class="sr-only" [(ngModel)]="isChecked" (change)="toggleSwitch()" />
        <div class="block w-12 h-6 bg-[#ced2da] rounded-full box" [ngClass]="boxClasses"></div>
        <div [ngClass]="dotClasses" class="absolute w-4 h-4 transition rounded-full dot left-1 top-1"></div>
    </div>
    <div class="ml-3 font-medium" (click)="labelClicked()" [ngClass]="labelClasses">{{ label }}
        <i *ngIf="!isChecked && hasChevron" class="ml-1 text-xs fas fa-chevron-down"></i>
    </div>
</label>