export enum DialogType {
    SimpleDilaog = 1,
    ConfirmationDialog = 2,
    SaveDialog = 3,
    CompilationError = 4,
    CompilationError_CLS = 5,
    ProductLineConflict = 6,
    ProductLineConflict_WAS = 7,
    ProductLineConflict_CatalogSelection = 8,
    ProductLineConflict_IncompatibleAttributeValueSelection_Build = 9,
    ProductLineConflict_IncompatibleAttributeValueSelection_Configure = 10,
    ModelAfter = 12,
    WASWarning = 13,
    PTValidation_BuildError = 14,
    PTValidation_ConfigError = 15,
    PTValidation_ConfigConflictError = 16,
    CompilationError_CatalogSelection = 17,
    Catalog_ReplaceValve = 18,
    Catalog_ReplaceProductFamily = 19,
}

export enum ConfirmationOption {
    isOk = 1,
    isYes = 2,
    isNo = 3,
    isSave = 4,
    isLeave = 5,
    isIgnore = 6
}

export enum KOBEnum {
    KOB1 = 1,
    KOB2 = 2
}

export const KOBList = [
    { kobId: KOBEnum.KOB1, kobName: "KOB1", cssClass: "custom-chip-size-KOB1" },
    { kobId: KOBEnum.KOB2, kobName: "KOB2", cssClass: "custom-chip-size-KOB2" }
]

export enum SerialTabEnum {
    ConstructionDetails = 1,
    Attributes = 2,
    RecommendedSpares = 3,
    EngineeringBom = 4,
    ShippedBom = 5,
    Documentation = 6,
    FieldChanges = 7,
    WalkDownReport = 8,
    SerialCompare = 9
}

export enum SearchFilterType {
    AdvancedSearch = 0,
    RecentSearch = 1
}

export enum PricingWorldRegion {
    AP = "AP",
    MEA = "MEA",
    EU = "EU",
    NA = "NA"
}