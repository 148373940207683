import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
})
export class AlertComponent {
  @Input() title: string = '';
  @Input() message: string | null = null;
  @Input() type: 'success' | 'warning' = 'success';
  @Input() closable: boolean = false;
  @Input() toggleable: boolean = false;
  @Output() closed = new EventEmitter<void>();

  isContentVisible: boolean = false;

  get bgColor(): string {
    return this.type === 'success' ? 'bg-[#d8f2e5]' : 'bg-[#fde6e6]';
  }

  get iconClass(): string {
    return this.type === 'success'
      ? 'fas fa-check-circle text-green-light'
      : 'fas fa-exclamation-circle text-[#C20A0A]';
  }

  toggleContent() {
    this.isContentVisible = !this.isContentVisible;
  }

  closeNotification() {
    this.closed.emit(); // Emit an event when the notification is closed
  }
}
