import { GetWorkspaceRequest, Workspace } from '../../models/workspace/workspace';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Kob } from '../../models/workspace/kob';
import { Status } from '../../models/status';
import { Config } from '../../config';
import { Injectable } from '@angular/core';
import { GridApi } from 'ag-grid-community';

import { CommonApiService } from '../common-api.service';
import { IWorkspaceTreeViewData } from 'src/app/pages/workspace-treeview/model/project-tree-view.model';
import { KOB3 } from '../../models/workspace/kob3';


@Injectable({
  providedIn: 'root'
})
export class WorkspaceService { 
  private config: Config;
  public workspace: Workspace;

  public addTagsRowData = new Subject();
  addTagsRowDataObs = this.addTagsRowData.asObservable();

  public updateTagGroupData = new Subject();
  updateTagGroupDataObs = this.updateTagGroupData.asObservable();

  public pwsSearchInputValue = new BehaviorSubject('');
  pwsSearchInputValue$ = this.pwsSearchInputValue.asObservable();

  routesName: string = '';

  constructor(private apiService: CommonApiService) {
    this.config = new Config();
  }

  public getProjectsDetailByUserId(projectRequest: GetWorkspaceRequest): Observable<Workspace[]> {
    return this.apiService.post(this.config.GET_WORKSPACES_API, projectRequest);
  }

  getKOBDetails(): Observable<Kob[]> {
    return this.apiService.getStaticData(this.config.GET_KOB_LIST_API);
  }
  getKOB3Details(): Observable<KOB3[]> {
    return this.apiService.getStaticData(this.config.GET_KOB3_LIST_API);
  }
  getProjectSummary(workspaceId: number, userId: number): Observable<IWorkspaceTreeViewData> {
    let url = `${this.config.GET_TREEVIEW_SUMMARY_API}?workspaceId=${workspaceId}&userId=${userId}`;
    return this.apiService.getJSON(url);
  }

  getWorkspaceStatusList(): Observable<Status[]> {
    return this.apiService.get(this.config.GET_WORKSPACE_STATUS_LIST_API);
  }

  createWorkspace(_workspace: string) {
    return this.apiService.post(this.config.CREATE_WORKSPACE_API, _workspace);
  }

  copyWorkspace(_workspace: string) {
    return this.apiService.post(this.config.POST_COPY_WORKSPACE_API, _workspace);
  }

  deleteWorkspace(_workspace: object) {
    return this.apiService.delete(this.config.DELETE_WORKSPACE_API, _workspace);
  }

  updateWorkspace(data: any) {
    this.updateTagGroupData.next(data)
  }

  getAddItemsRowData(workspaceData: any) {
    this.addTagsRowData.next(workspaceData);
  }

  setWorkspaceDetails(data: Workspace){
    this.workspace = data;
  }

  setworkspaceName(workspaceName: string){
    if(this.workspace) this.workspace.workspaceName = workspaceName;
  }

  getworkspaceName(){
    if(this.workspace) return this.workspace.workspaceName;
    return "";
  }

  public getworkspaceId(){
    if(this.workspace) return this.workspace.workspaceId;
    return 0;
  }

  public getCurrencyCode(): string{
    if(this.workspace) return this.workspace.currencyCode;
    return "";
  }

  getpwsSearchInputValue(value: string){
    this.pwsSearchInputValue.next(value);
  }

  setRedirectRoute(routeName:string){
    this.routesName = routeName;
  }
}