export const environment = {
  // Production
  appName: "PRESTO QA",
  production: true,
  test: false,
  envName: "qa",
  i18nPrefix: "",
  
  graphUrl: "https://presto-graph-qa.emerson.com/",
  prestoApiUrl: "https://presto-api-qa.emerson.com",

  oktaRedirectUri: "https://presto-qa.emerson.com/oidc/callback",
  ISSUER: "https://accessemr.okta.com",
  CLIENT_ID: "0oavjjmhd8BMImKTE1t7",
}