import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CustomAutocompleteComponent } from './shared/components/custom-autocomplete/custom-autocomplete.component';
import { AuthInterceptor } from './shared/Interceptor/auth-interceptor';
import { StoreModule } from '@ngrx/store';
import { treeDataReducer } from './store/treedata.reducer';

// Material Modules
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from './shared/shared.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Config } from './shared/config';
import { PRESTOSecurityModule } from 'projects/presto-security/src/lib/presto-security.module';
import { Constant } from './shared/constants/constant';
import { LayoutComponent } from './layout/layout.component';
import { DialogModule } from 'primeng/dialog';
import { GraphQLModule } from './graphql.module';


const config = new Config();
@NgModule({ declarations: [AppComponent, CustomAutocompleteComponent, LayoutComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        MatAutocompleteModule,
        AppRoutingModule,
        RouterModule,
        MatSelectModule,
        MatMenuModule,
        MatDialogModule,
        MatCardModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        DialogModule,
        PRESTOSecurityModule.forRoot(config.GET_USERINFO_API, Constant.APPLICATION_NAME, Constant.AUTH_ERROR_REDIRECT),
        StoreModule.forRoot({ treeData: treeDataReducer }),
        GraphQLModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
