import { AssignmentState } from "src/app/shared/enums/buildassembly";

export class AttributeValue {
    name?: string;
    id?: number;
    attrValueId?: number;
    state?: AssignmentState;
    sortingField?: number;
    isSelected?:boolean;

    constructor() {
        this.name = "";
    }
}