import { Component, EventEmitter, HostListener, Input, Output, output, signal } from '@angular/core';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { MroApiSerialService } from 'src/app/shared/services/mro/mro-api-serial.service';
import { BulkSerialSearchResult } from 'src/app/shared/models/mro/bulk-serial';
import { Router } from '@angular/router';
import { DownloadType } from 'src/app/shared/enums/mro/download-type';
@Component({
  selector: 'app-bulk-search',
  templateUrl: 'bulk-search.component.html',
  styleUrl: 'bulk-search.component.scss'
})
export class BulkSearchComponent {

  faClock = faClock;
  selectedFile: File | null = null;
  fileName:string = '';
  fileSize:number = 0;
  noConttentFound = signal<string>('');
  bulkUploadType = DownloadType.Serial;

  @Input() searchOptions: {label: string, value: string, checked: boolean}[] = [];
  selectedValue: string = '1';

  @Output() bulkDisplay = new EventEmitter<false>();
  @Output() displaySearch = new EventEmitter<boolean>(true);

  constructor(private _searchService: MroApiSerialService, private router: Router) { }

  onBulkDisplay() {
    this.bulkDisplay.emit(false);
  }
  
  onBulkReset() {
    this.selectedFile = null;
    this.fileName = '';
    this.fileSize = 0;
    this.selectedValue = '1';
  }
  
  onFileChange(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      const files = event.target.files;
      if (files && files.length > 0) {
        this.selectedFile = files[0];
        event.target.value = '';
      }
    }
  }
  getFileSize() {
    if (this.selectedFile) {
      return Math.round(this.selectedFile.size / 1024);
    }
    return 0;
  }
  triggerFileInput(fileInput: HTMLInputElement) {
    fileInput.click();
  }

  downloadTemplate() { 
    this._searchService.getBulkSearchDownlaodTemplate(this.bulkUploadType).subscribe({
      next: (data: Blob) => {
        this._searchService.downloadExcelFile(data, 'MROBulkSearchUploadTemplate');
        this.displaySearch.emit(true);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  onCloseNoResults() {
    this.noConttentFound.set('');
  }

  onApplySearch() {
    if (this.selectedFile) {
      this._searchService.postBulkSearchUpload(this.selectedValue, this.selectedFile).subscribe({
        next: (response:BulkSerialSearchResult) => {
          if(response.validSerialCards.serialCards.length > 0){
            const selectedLabel = this.searchOptions.find((option) => option.value == this.selectedValue)?.label;
            response.validSerialCards.serialCards[0].searchType = selectedLabel || '';
            response.validSerialCards.serialCards[0].searchedValue = 'Bulk Search';
            this._searchService.getBulkSerialResult(response);
            this.router.navigate(['search']);
            this.displaySearch.emit(false);
          }else {
            this.noConttentFound.set('No Content Found for the search criteria.');
          }
        },
        error: (error) => {
          console.log(error);
          this.noConttentFound.set('No Content Found for the search criteria.');
        }
      });
    } else {
      console.log('No file selected.');
    }
  }
}
