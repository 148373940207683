/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, Subject, fromEvent, takeUntil } from 'rxjs';
import { BrowserNavigationPopupComponent } from '../components/popups/browser-navigation-popup/browser-navigation-popup.component';
import { WarningPopupComponent } from '../components/popups/warning-popup/warning-popup.component';
import * as moment from 'moment';
import { GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-community';
import { ISummaryView } from 'src/app/pages/workspace-treeview/model/project-tree-view.model';

@Injectable({
  providedIn: 'root',
})
export class CommonUtilityLibraryService {
  private destinationPageKey = 'destinationPage';
  private unsubscriber: Subject<void> = new Subject<void>();
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  summaryView = {} as ISummaryView;
  constructor(private _snackBar: MatSnackBar, private dialog: MatDialog) {
    this.tagSubject$ = new BehaviorSubject(this.summaryView);
  }
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  exclusive = new Subject<boolean>();
  tagSubject$: BehaviorSubject<ISummaryView>;

  public setTagData(data: ISummaryView){
    this.tagSubject$.next(data);
  }

  public onChangeTagData(): Observable<ISummaryView> {
    return this.tagSubject$.asObservable();
  }

  public isLoadingFlagChanged(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }

  showSnackbar(message: string, className: string) {
    this._snackBar.open(message, '  ', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 2000,
      panelClass: [className],
    });
  }
  // Accept only alpha numerics, not special characters
  public static validateInputKeyAlphaNumeric(e: any): boolean {
    const regex = new RegExp('^[a-zA-Z0-9 ]+$');
    const str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }

  // Accept only alpha numerics, not special characters on Paste
  public static validateInputAlphaNumericOnPaste(e: any) {
    e.preventDefault();
    return false;
  }

  statusUpdated = new EventEmitter<string>();
  updateStatus(id: number, status: string) {
    this.statusUpdated.emit(status);
  }

  copyWorkspaceStatusUpdatedForListview = new EventEmitter<string>();
  copyWorkspaceUpdateStatusForListview(id: number, status: string) {
    this.copyWorkspaceStatusUpdatedForListview.emit(status);
  }

  deleteWorkspaceStatusUpdatedForListview = new EventEmitter<string>();
  deleteWorkspaceUpdateStatusForListview(id: number, status: string) {
    this.deleteWorkspaceStatusUpdatedForListview.emit(status);
  }

  show() {
    this.isLoading$.next(true);
  }

  hide() {
    this.isLoading$.next(false);
  }

  disableBrowserBack() {
    history.pushState(null, '');
    return fromEvent(window, 'popstate').pipe(takeUntil(this.unsubscriber));
  }

  openBrowserNavigationPopup() {
    this.dialog.open(BrowserNavigationPopupComponent);
  }

  openWarningPopup(warningPopupData: any) {
    this.dialog.open(WarningPopupComponent, { data: warningPopupData });
  }

  setDestinationPage(page: string): void {
    sessionStorage.setItem(this.destinationPageKey, page);
  }

  getDestinationPage(): string | null {
    return sessionStorage.getItem(this.destinationPageKey);
  }

  clearDestinationPage(): void {
    sessionStorage.removeItem(this.destinationPageKey);
  }

  dateComparator(date1: any, date2: any) {
    const momentDate1 = moment(date1 ?? '01-January-1900', 'DD-MMMM-YYYY');
    const momentDate2 = moment(date2 ?? '01-January-1900', 'DD-MMMM-YYYY');
    return momentDate1.diff(momentDate2, 'days');
  }

  month = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  dateValueFormetter(value: any) {
    let date = new Date(value);
    let day = date.getDate().toString().padStart(2, '0');
    let month = this.month[date.getMonth()].toString().padStart(2, '0');
    let year = date.getFullYear().toString().substring(0);
    return day + '-' + month + '-' + year;
  }
  
  getContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
    const Export = []
    if(params.node && params.node.data){
      Export.push({
        name: 'Export',
        icon: '<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>',
        subMenu: [
          {
            name: 'CSV',
            icon: '<span class="ag-icon ag-icon-csv" unselectable="on" role="presentation"></span>',
            action: () => { params.api.exportDataAsCsv() }
          },
          {
            name: 'XLSX',
            icon: '<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation"></span>',
            action: () => { params.api.exportDataAsExcel() }
          }
        ]
      })
    }
    return Export;
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
