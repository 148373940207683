import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonUtilityLibraryService } from 'src/app/shared/library/common-utility-library.service';
import { Router } from '@angular/router';
import { ValveSizingRequestClass } from 'src/app/pages/tag-workspace/store/models/sizevalve/valve.sizing.request.class';
import { ItemIdentifier } from '../../models/project-worksapce/itemidentifier';

@Injectable({
  providedIn: 'root'
})

export class UtilsService {
  sessionId$: BehaviorSubject<string> = new BehaviorSubject("");
  successMsgTitle: string = "Save message\n" + "Your data saved Succesfully";
  tagWorkspaceNavigation = new Subject();
  tagWorkspaceNavigation$ = this.tagWorkspaceNavigation.asObservable();

  constructor(
    public culibrary: CommonUtilityLibraryService,
    public router: Router) { }

  public updateTagRev = new Subject();
  updateTagRev$ = this.updateTagRev.asObservable();
  updateBreadcrum(rev: any) {
    this.updateTagRev.next(rev);
  }

  headerLogoClicked(url: string){
    this.tagWorkspaceNavigation.next(url);
  }


}