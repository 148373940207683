import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayFirstAndLastChar',
  standalone: true
})
export class DisplayFirstAndLastCharPipe implements PipeTransform {

  transform(value: string): string {
    if(!value) return value;
    let words = value.split(/[\s-,/;:.]+/).filter(word => word !== '')
    let firstWord = words.length === 1 ? words[0].slice(0, 2): words[0].slice(0, 1);
    let lastWord = words.length === 1 ? '' : words[1].slice(0, 1);
    return firstWord.toUpperCase() + lastWord.toUpperCase();
  }

}
