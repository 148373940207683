import { PricingWorldRegion } from "../../enums/common";

export class PricingHeader {
    userId: string;
    workspaceId: number;
    conversionFactor: string;
    countryCode: string;
    currency: string;
    currencyCode: string;
    effectiveDate: string;
    lcid: string;
    officeId: string;
    region: PricingWorldRegion;
    roundingOption: string;
    nearestPlaceValue: number;
    effectiveDateChange: boolean;
    currencyOrOfficeChange: boolean;

    constructor() {
        this.userId = "1";
        this.workspaceId = 0;
        this.conversionFactor = "81.367";
        this.countryCode = "F50";
        this.currency = "AP";
        this.currencyCode = "INR";
        this.effectiveDate = "2022-11-15";
        this.lcid = "1081";
        this.officeId = "077";
        this.region = PricingWorldRegion.AP;
        this.roundingOption = "None";
        this.nearestPlaceValue = 0.01;
        this.effectiveDateChange = false;
        this.currencyOrOfficeChange = false;
    }
}