<!-- button.component.html -->
<button [ngClass]="computeButtonClasses()" [type]="type" [disabled]="disabled">
    <ng-container *ngIf="!hasIconTemplate">
        <ng-container *ngIf="hasIcon && iconPlacement === 'left'">
            <img *ngIf="iconSrc && !ico" [src]="iconSrc" alt="Icon" class="mr-1" />
            <i *ngIf="iconSrc && ico" [ngClass]="iconSrc"></i>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="hasIconTemplate">
        <ng-content select="[appIconTemplate]"></ng-content>
    </ng-container>
    <ng-container *ngIf="hasIcon">
        <ng-content select="[appIconTemplate]"></ng-content>
    </ng-container>
    <ng-content></ng-content> <!-- Project additional content here -->

    {{ label }}
    <ng-container *ngIf="hasIcon && iconPlacement === 'right'">
        <img *ngIf="iconSrc" [src]="iconSrc" alt="Icon" class="ml-1" />
    </ng-container>
</button>