<div [ngClass]="bgColor" class="p-3 w-full rounded-[10px] justify-start items-center gap-3 inline-flex">
    <div>
        <i [ngClass]="iconClass" class="flex text-lg shrink-0"></i>
    </div>
    <div class="inline-flex flex-col items-start justify-start w-full">
        <div class="text-sm font-semibold leading-normal text-black font-inter">{{ title }}</div>
        <div *ngIf="message" class="text-xs font-normal leading-tight text-black font-inter">{{ message }}</div>
    </div>
    <div *ngIf="closable" class="cursor-pointer" (click)="closeNotification()">
        <i
            class="flex text-lg transition-all shrink-0 fas fa-times-circle text-green-light hover:opacity-60 hover:transition-all"></i>
    </div>
    <div *ngIf="toggleable" class="cursor-pointer" (click)="toggleContent()">
        <i class="flex text-sm text-black transition-all shrink-0 fas"
            [ngClass]="isContentVisible ? 'fa-chevron-up' : 'fa-chevron-down'" hover:opacity-60
            hover:transition-all></i>
    </div>
</div>

<div *ngIf="toggleable && isContentVisible" class="w-full">
    <ng-content></ng-content>
</div>