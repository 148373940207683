<div class="search gap-2 p-2">
  <div class="search flex justify-between items-start px-2 py-[10px]">
      <div class="search px-2 font-semibold text-sm text-black flex justify-between">
          Start your Upload the bulk search template to start with
      </div>
      <app-button
      [label]="'Normal Search'"
      [theme]="'outline-green'"
      (click)="onBulkDisplay()"
    >
    </app-button>
  </div>
  <div class="search grid grid-flow-col gap-x-2.5 grid-cols-auto mb-2">
      <div class="search flex flex-col justify-center gap-2.5">
        <div class="flex px-4 text-sm py-2 bg-gradient-to-b from-white to-[#F0F0F0] flex-wrap w-full gap-2 2xl:gap-2 ">
          @for (option of searchOptions; track option.value) {
          <label class=" search inline-flex items-center cursor-pointer gap-2" [ngClass]="{'disabled': option.value === '6'|| option.value === '8' }">
            <input type="radio" id="radio" [(ngModel)]="selectedValue"
            [value]="option.value" 
              />
            <span class="search ml-2 text-sm 2xl:text-base font-normal text-gray-700" class="">{{ option.label
              }}</span>
          </label>
          }
        </div>
      </div>
    </div>
  <div class="search">
      <div class="flex justify-between items-center px-3 py-[10px]">
         
          <div class="search">
              <input #fileInput hidden type="file" (change)="onFileChange($event)" accept=".xls, .xlsx" />
              <button (click)="triggerFileInput(fileInput)"
                  class="px-4 py-2 font-semibold text-sm border text-green-primary border-green-primary rounded-lg hover:!bg-green-500 hover:!text-white">
                 {{selectedFile ? 'Replace': 'Choose'}} File
              </button>
          </div>

          <div class="search flex">
              <span class="font-normal text-sm">If you don't have the search template handy,</span><span
                  class="text-[#1A75FF] font-normal text-sm cursor-pointer" (click)="downloadTemplate()">download it here</span>
          </div>
      </div>
  </div>
  <div class="search flex justify-between items-center px-2 py-[10px]">
      @if(selectedFile) {
          <div
              class="search w-full flex p-3 items-start gap-3 self-stretch rounded-[10px] border-0 border-[#33A9FF] bg-[#D9F2E5] mb-2">
              <img src="assets/icons/success.svg" alt="successicon" />
              <div class="search self-stretch text-[#141C24] text-xs 2xl:text-sm font-semibold flex-[1_0_0%]">
                  <div class="flex flex-col items-start justify-start">
                      Successfully Uploaded Search Template
                  </div>
              </div>
          </div>
      }
  </div>
  <div class="search justify-between items-center">
      @if (noConttentFound().length > 0) {
          <div class="search flex p-3 m-3 items-start gap-3 self-stretch rounded-[10px] bg-[#FEE7E7]">
            <div class="search notify">
              <img src="assets/icons/Warning_yellow.svg" alt="" />
            </div>
            <div class="search flex flex-col w-full">
              <div class="self-stretch text-[#141C24] text-xs 2xl:text-sm font-semibold">
                We couldn’t find any results for your search
              </div>
              <div class="search self-stretch text-[#344051] text-xs 2xl:text-sm font-normal">
                Try searching again with different keyword(s) / filters
              </div>
            </div>
            <div class="search notify">
              <img (click)="onCloseNoResults()" src="assets/icons/Close2.svg" alt="" />
            </div>
          </div>
          }
  </div>
  <div class="search flex justify-between items-center px-2 py-[10px]">
      @if (selectedFile) {
          <div class="search flex items-start px-2 py-[10px]">
              <fa-icon [icon]="faClock" class="mr-1 text-neutral-400"></fa-icon>
              <span class="font-medium mr-1">{{selectedFile.name}}</span>
              <span class="text-gray-600">{{getFileSize()}} KB</span>
          </div>
      }
  </div>
</div>
<div class="search flex w-full px-4 gap-x-2.5 mt-1 mb-4 text-sm">
  <button type="submit" (click)="onApplySearch()" [disabled]="!selectedFile" [ngClass]="{'btn-disabled': !selectedFile}"
      class="shrink-0 whitespace-nowrap h-10 px-3 py-2.5 rounded-lg justify-center items-center gap-2 inline-flex text-white border border-gray-100 !text-xs !2xl:text-sm font-semibold font-inter !bg-green-primary border-green-primary hover:!bg-green-light duration-300 !transition-all hover:!transition-all hover:!border-green-light transiton-all">
      Apply Search
  </button>
  <button type="button" (click)="onBulkReset()" theme="default"
      class="shrink-0 whitespace-nowrap h-10 px-3 py-2.5 rounded-lg justify-center items-center gap-2 inline-flex border border-gray-100 text-gray-700 !text-xs !2xl:text-sm font-semibold font-inter !bg-gray-100">
      Reset
  </button>
</div>