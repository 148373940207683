import { AssignedBy, AttributeType } from "src/app/shared/enums/buildassembly";

export class Assignment {
    groupName?: string;
    guid?: string;
    attributeId?: number;
    variableId?: number;
    variableName?: string;
    assignedBy?: AssignedBy;
    valueName?: string;
    attrValueId?: number;
    valueId?: number;
    isBroken?: boolean;
    attributeType?: AttributeType;
    assignmentType?: string;
    specialValue?: string;
    value?: string; //To handle values coming from Conflict Assignments

    constructor() {
    }
}